import * as React from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import Layout from "../../components/layout";

// markup
const Index: React.FC = () => {
  return (
    <Layout pageTitle="Portfolio - Light Infotech Software Solutions">
      <main id="main">
        <BreadCrumbs visitPageTitle={"Portfolio"} />
      </main>
    </Layout>
  );
};

export default Index;
